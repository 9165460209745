import axios from "axios";

const instanse = axios.create({
  baseURL: "https://bulka.yesoleg.ru/api/bot/v1",
});

instanse.interceptors.request.use(
  (config) => {
    let webApp = window.Telegram.WebApp;
    console.log(webApp.initData);

    const baseURL = instanse.defaults.baseURL || "";
    // Добавляем query параметр к каждому запросу
    const url = new URL(baseURL + config.url + "?" + webApp.initData);
    // url.searchParams.set("query_id", "AAEL-ggYAAAAAAv6CBiiUn6N");
    // url.searchParams.set(
    //   "user",
    //   '{"id":403241483,"first_name":"Alisher","last_name":"Muratov","username":"FrontFullHD","language_code":"ru","allows_write_to_pm":true}'
    // );
    // url.searchParams.set("auth_date", "1691005400");
    // url.searchParams.set(
    //   "hash",
    //   "0c29307e6fd152b35fbffa190b2aa410a4d1666bb7d415715f6e0c201c87a5a7"
    // );
    // url.searchParams.
    config.url = url.toString();

    return config;
  },
  (error) => {
    if (error.response && error.response.status === 500) {
      alert("Произошла ошибка на сервере. Пожалуйста, попробуйте позже.");
      // Или перенаправьте пользователя на определенную страницу
      // router.push('/error-page');
    }
    return Promise.reject(error);
  }
);

export default instanse;
