import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/config/globalComponents";
import "./assets/scss/style.scss";
import "@/plugins/telegram";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import api from "./api";

Vue.config.productionTip = false;

Vue.component("v-select", vSelect);
Vue.prototype.$bus = new Vue();
Vue.prototype.api = api;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
