<template>
  <div v-if="visible" class="snackbar">
    {{ message }}
  </div>
</template>

<script>
export default {
  name: "CSnackbar",
  data() {
    return {
      visible: false,
      message: "",
      timeout: null,
    };
  },
  methods: {
    show(message, duration = 3000) {
      this.message = message;
      this.visible = true;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.visible = false;
      }, duration);
    },
  },
  mounted() {
    this.$bus.$on("show-snackbar", this.show);
  },
  beforeDestroy() {
    this.$bus.$off("show-snackbar", this.show);
  },
};
</script>

<style>
.snackbar {
  position: fixed;
  width: 90%;
  border-radius: 12px;
  top: 80px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #dc3545;
  color: #fff;
  padding: 14px 24px;
  border-radius: 4px;
  z-index: 9999;
}
</style>
