<template>
  <header class="header" ref="header">
    <div class="header__block">
      <base-icon @click.native="returnLink()" />
      <h1>{{ headerTitle }}</h1>

      <base-icon
        v-if="currentRouteName"
        iconName="refresh"
        width="24"
        height="24"
      />
      <div v-else style="width: 24px"></div>
    </div>
    <div class="header__catalog" v-if="isCatalog">
      <p
        :class="{ active: active === i }"
        v-for="(item, i) in catalog"
        :key="i"
        @click="goTo(`catalog-${i}`, i)"
      >
        {{ item }}
      </p>
    </div>
  </header>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "CHeader",
  data() {
    return {
      active: 0,
    };
  },
  computed: {
    ...mapGetters({
      headerTitle: "getHeaderTitle",
      catalog: "getHeaderCatalog",
    }),
    currentRouteName() {
      return this.$route.name === "order-detail";
    },
    isCatalog() {
      return this.$route.name === "catalog";
    },
  },
  methods: {
    returnLink() {
      const url = this.$route.meta.return || "order";
      this.$router.push({ name: url }).catch(() => {});
    },
    goTo(sectionId, i) {
      const element = document.getElementById(sectionId);

      if (element) {
        const topOffset = 150; // отступ в 50 пикселей, можете изменить по своему усмотрению

        // Вычисляем позицию скроллирования с учетом отступа
        const topPosition =
          element.getBoundingClientRect().top + window.pageYOffset - topOffset;

        window.scrollTo({
          top: topPosition,
          behavior: "smooth",
        });
        this.active = i;
      }
    },
  },
};
</script>

<style lang="scss">
.header {
  background: #ffffff;
  z-index: 10;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  min-height: 60px;
  padding: 0 15px;
  box-shadow: 0 4px 6px -2px lightgray;
  &__block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0 23px;
  }
  &__catalog {
    padding: 10px 0 15px;
    display: flex;
    gap: 13px;
    overflow-y: auto;
    & > p {
      padding: 10px 16px;
      border-radius: 6px;
      background: #e9ecef;
      font-size: 18px;
      font-weight: 600;
      color: #343a40;
      white-space: nowrap;
    }
    .active {
      background: #ebe5fc;
      color: #6610f2;
    }
  }
  h1 {
    font-size: 18px;
    font-weight: 600;
  }
}
</style>
