<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="viewBox"
    :aria-labelledby="iconName"
    role="presentation"
    v-html="icon"
  ></svg>
</template>

<script>
import icons from "./icons";
export default {
  name: "CBaseIcon",
  props: {
    iconName: {
      type: String,
      default: "arrowLeft",
    },
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
  },
  computed: {
    icon() {
      return icons[this.iconName];
    },
    viewBox() {
      return `0 0 ${this.width} ${this.height}`;
    },
  },
};
</script>
