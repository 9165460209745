<template>
  <div id="app">
    <c-snackbar />
    <c-header />
    <div class="mt-60"></div>
    <router-view />
  </div>
</template>
<script>
import CSnackbar from "@/components/CSnackBar";
import CHeader from "@/components/CHeader";
export default {
  name: "VApp",
  components: {
    CHeader,
    CSnackbar,
  },
  created() {
    if (process.browser) {
      let recaptchaScript = document.createElement("script");
      recaptchaScript.setAttribute(
        "src",
        "https://www.google.com/recaptcha/api.js"
      );
      document.body.appendChild(recaptchaScript);
    }
  },
  data() {
    return {
      height: "60px",
    };
  },
  mounted() {
    // let recaptchaScript = document.createElement("script");
    // recaptchaScript.setAttribute(
    //   "src",
    //   "https://telegram.org/js/telegram-web-app.js"
    // );
    // document.head.appendChild(recaptchaScript);
    // console.log(window.Telegram.WebApp);
    // this.height = this.$refs.header.$el.clientHeight + "px";
    // console.log(this.height);

    let webApp = window.Telegram.WebApp;
    console.log(webApp.initData);
  },
};
</script>

<style lang="scss" scoped>
.mt-60 {
  margin-top: 82px;
}
</style>
