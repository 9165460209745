import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "order",
    component: () => import("../views/OrderView.vue"),
  },
  {
    path: "/order/:id",
    name: "order-detail",
    meta: {
      return: "order",
    },
    component: () => import("../views/OrderDetailView.vue"),
  },
  {
    path: "/catalog",
    name: "catalog",
    meta: {
      return: "order",
    },
    component: () => import("../views/CatalogView.vue"),
  },
  {
    path: "/checkout",
    name: "checkout",
    meta: {
      return: "catalog",
    },
    component: () => import("../views/CheckoutView.vue"),
  },
  {
    path: "/confirmation",
    name: "confirmation",
    meta: {
      return: "checkout",
    },
    component: () => import("../views/ConfirmationOrderView.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
