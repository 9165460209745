export default (instance) => {
  return {
    getCatalog() {
      return instance.get("/catalog");
    },
    getOrders() {
      return instance.get("/orders");
    },
    getOrderDetail(id) {
      return instance.get(`/order/${id}`);
    },
    getImage(id) {
      return instance.post("/images", { ids: [{ id, size: "full" }] });
    },
    getRules() {
      return instance.get("/delivery/rules");
    },
    postCalc({ countDeliveriesId, weekDaysIds, timeDeliveryId, cart }) {
      return instance.post("/cart/calc", {
        countDeliveriesId,
        weekDaysIds,
        timeDeliveryId,
        cart,
      });
    },
    cancelOrder(orderId) {
      return instance.get(`/order/${orderId}/cancel-code`);
    },
    postCreateOrder(cart, selects) {
      selects.flat = `${selects.flat}`;
      const newCart = cart.map((el) => {
        return { itemId: el.itemId, count: el.count };
      });
      console.log(newCart, selects);
      return instance.post("/order/create", {
        ...selects,
        cart: newCart,
      });
    },
  };
};
