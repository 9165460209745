export default {
  arrowLeft: `<line x1="18" y1="9" x2="1" y2="9" stroke="black"/>
  <line x1="8.19042" y1="0.312348" x2="1.39042" y2="8.81235" stroke="black"/>
  <line x1="9.14645" y1="17.3536" x2="0.646448" y2="8.85355" stroke="black"/>`,
  plus: `<line x1="23" x2="23" y2="45" stroke="#C0C0C0" stroke-width="4"/>
  <line x1="45" y1="22" y2="22" stroke="#C0C0C0" stroke-width="4"/>`,
  refresh: `<path fill-rule="evenodd" clip-rule="evenodd" d="M11.9999 4.5C10.379 4.50062 8.80183 5.02639 7.50475 5.99856C6.20766 6.97072 5.26045 8.33696 4.80498 9.89262C4.34952 11.4483 4.41031 13.1096 4.97827 14.6278C5.54622 16.1461 6.59076 17.4394 7.95543 18.3142C9.3201 19.1889 10.9315 19.598 12.5481 19.4801C14.1648 19.3623 15.6998 18.7238 16.9232 17.6604C18.1465 16.597 18.9925 15.1658 19.3342 13.5813C19.676 11.9968 19.4952 10.3442 18.8189 8.871C18.7453 8.69189 18.7438 8.49126 18.8148 8.31108C18.8858 8.13091 19.0237 7.98516 19.1996 7.90435C19.3756 7.82353 19.576 7.81391 19.7589 7.8775C19.9418 7.94109 20.093 8.07295 20.1809 8.2455C20.9924 10.0134 21.2093 11.9966 20.799 13.8981C20.3888 15.7996 19.3735 17.5169 17.9053 18.793C16.4371 20.069 14.595 20.835 12.6549 20.9762C10.7148 21.1175 8.78117 20.6263 7.14364 19.5764C5.50611 18.5264 4.25283 16.9742 3.57153 15.1522C2.89023 13.3301 2.81759 11.3364 3.36449 9.46967C3.91138 7.6029 5.04838 5.96357 6.60516 4.79723C8.16194 3.63089 10.0547 3.00033 11.9999 3V4.5Z" fill="black"/>
  <path d="M12 6.69913V0.801129C12 0.729874 12.0204 0.660101 12.0586 0.599981C12.0969 0.539861 12.1514 0.491882 12.216 0.461663C12.2805 0.431444 12.3523 0.420236 12.423 0.42935C12.4936 0.438465 12.5602 0.467526 12.615 0.513129L16.155 3.46213C16.335 3.61213 16.335 3.88813 16.155 4.03813L12.615 6.98713C12.5602 7.03273 12.4936 7.06179 12.423 7.07091C12.3523 7.08002 12.2805 7.06881 12.216 7.0386C12.1514 7.00838 12.0969 6.9604 12.0586 6.90028C12.0204 6.84016 12 6.77038 12 6.69913Z" fill="black"/>
  `,
  warning: `
  <circle cx="17" cy="17" r="17" fill="#FFB800"/>
  <line x1="17" y1="8" x2="17" y2="20" stroke="white" stroke-width="2"/>
  <path d="M16 25H18" stroke="white" stroke-width="2"/>
  `,
  cancel: `
    <g clip-path="url(#clip0_250_390)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 22.5C14.7848 22.5 17.4555 21.3938 19.4246 19.4246C21.3938 17.4555 22.5 14.7848 22.5 12C22.5 9.21523 21.3938 6.54451 19.4246 4.57538C17.4555 2.60625 14.7848 1.5 12 1.5C9.21523 1.5 6.54451 2.60625 4.57538 4.57538C2.60625 6.54451 1.5 9.21523 1.5 12C1.5 14.7848 2.60625 17.4555 4.57538 19.4246C6.54451 21.3938 9.21523 22.5 12 22.5ZM12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24Z" fill="black"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6 12C6 11.8011 6.07902 11.6103 6.21967 11.4697C6.36032 11.329 6.55109 11.25 6.75 11.25H17.25C17.4489 11.25 17.6397 11.329 17.7803 11.4697C17.921 11.6103 18 11.8011 18 12C18 12.1989 17.921 12.3897 17.7803 12.5303C17.6397 12.671 17.4489 12.75 17.25 12.75H6.75C6.55109 12.75 6.36032 12.671 6.21967 12.5303C6.07902 12.3897 6 12.1989 6 12Z" fill="black"/>
    </g>
    <defs>
    <clipPath id="clip0_250_390">
    <rect width="24" height="24" fill="white"/>
    </clipPath>
    </defs>
  `,
  exitModal: `<circle cx="28" cy="28" r="28" fill="#F5F5F5"/>
    <line x1="18.3692" y1="16.6629" x2="39.3692" y2="39.6629" stroke="black"/>
    <line x1="17.6308" y1="39.6629" x2="38.6308" y2="16.6629" stroke="black"/>`,
};
