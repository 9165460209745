const actions = {
  setHeaderTitle({ commit }, title) {
    commit("UPDATE_HEADER_TITLE", title);
  },
  setHeaderCatalog({ commit }, title) {
    commit("UPDATE_HEADER_CATALOG", title);
  },
  addItemCart({ commit }, item) {
    commit("UPDATE_CART", item);
  },
  addSavedCart({ commit }, item) {
    commit("UPDATE_SAVED_CART", item);
  },
};

export default actions;
